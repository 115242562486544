import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { HelmetProvider } from 'react-helmet-async';
import queryString from 'query-string';

import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Layout from '../components/layout';
import SEO from '../utils/SEO';
import LogEventOnMount from '../components/LogEventOnMount';

const Bold = ({ children }) => (
  <span style={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold' }}>
    {children}
  </span>
);
const Text = ({ children }) => (
  <p style={{ fontFamily: 'Source Sans Pro' }}>{children}</p>
);

const Hyperlink = ({ children }) => (
  <span
    style={{ fontWeight: 'bold', textDecoration: 'underline', color: 'red' }}
  >
    {children}
  </span>
);

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      if (node.data.target.fields) {
        const { title, file } = node.data.target.fields;
        return (
          <img
            alt={title || null}
            src={file['en-US'].url || file.url || null}
          />
        );
      }
      return null;
    },
  },
};

const BlogPostTemplate = ({ location, data: { contentfulPost } }) => {
  const eventProperties = () => {
    const marketingCampaign = queryString.parse(location.search).utm_campaign;
    const properties = {
      title: contentfulPost.title,
      marketing_campaign: marketingCampaign,
    };

    return properties;
  };

  return (
    <HelmetProvider>
      <Layout location={location}>
        <SEO
          title={contentfulPost.title}
          description={contentfulPost.description}
          pathname={location.pathname}
          image={`https:${contentfulPost.image.fluid.src}`}
        />
        <div className="blogpost-page" role="main">
          <div className="header-image flip">
            <img
              className="scale flip"
              src={contentfulPost.image.fluid.src}
              alt={contentfulPost.image.title}
            />
          </div>
          <div className="blogpost-info">
            <span className="date">{contentfulPost.publishedAt}</span>
            <h1>{contentfulPost.title}</h1>
            <span className="description">{contentfulPost.description}</span>
          </div>
          <div className="blogpost-text">
            {documentToReactComponents(contentfulPost.body.json, options)}
          </div>
        </div>
      </Layout>
      <LogEventOnMount
        eventType="view blog post"
        eventProperties={eventProperties()}
      />
    </HelmetProvider>
  );
};

export default BlogPostTemplate;

BlogPostTemplate.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

Bold.propTypes = {
  children: PropTypes.string.isRequired,
};

Text.propTypes = {
  children: PropTypes.array.isRequired,
};

Hyperlink.propTypes = {
  children: PropTypes.string.isRequired,
};

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      description
      publishedAt(formatString: "DD/MM/YYYY")
      body {
        json
      }
      author {
        name
        bio
        image {
          fixed(width: 100) {
            src
          }
        }
      }
      image {
        title
        fluid(maxWidth: 1800) {
          src
        }
      }
    }
  }
`;
